import axios from '@/libs/axios'

class Level {
  static list(params) {
    return axios.post('admin/levels', params)
  }

  static store(params) {
    return axios.post('admin/levels/store', params)
  }

  static view(id) {
    return axios.post(`admin/levels/view/${id}`)
  }

  static update(params) {
    return axios.post('admin/levels/update', params)
  }

  static destroy(id) {
    return axios.post('admin/levels/delete', { id })
  }
}

export default Level
